/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addFeedback = /* GraphQL */ `
  mutation AddFeedback($input: addFeedbackInput) {
    addFeedback(input: $input) {
      statusCode
      body
    }
  }
`;
export const pushLatencyMetrics = /* GraphQL */ `
  mutation PushLatencyMetrics($latency: Float!) {
    pushLatencyMetrics(latency: $latency) {
      statusCode
      body
    }
  }
`;
