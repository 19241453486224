import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';

import './refresh-modal.css';

type Props = {
  refreshConversation: () => void;
};

function RefreshModal({ refreshConversation }: Props) {
  const { t } = useTranslation();

  return (
    <Modal
      open
      closable={false}
      className="RefreshModal"
      title={t`RefreshModal.title`}
      footer={[
        <Button key="okButtonRefreshModal" className={'okButton'} onClick={refreshConversation}>
          {t`RefreshModal.okButton`}
        </Button>,
      ]}
    >{t`RefreshModal.content`}</Modal>
  );
}

export default RefreshModal;
