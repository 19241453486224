import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

import './overload-modal.css';

function RefreshModal() {
  const { t } = useTranslation();

  return (
    <Modal
      open
      closable={false}
      className="OverloadModal"
      title={t`OverloadModal.title`}
      footer={null}
    >
      <p>{t`OverloadModal.content`}</p>
    </Modal>
  );
}

export default RefreshModal;
