import { Amplify } from 'aws-amplify';

import ChatWidget from './pages/ChatWidget/ChatWidget.component';

import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

function App() {
  return (
    <>
      <ChatWidget />
    </>
  );
}

export default App;
