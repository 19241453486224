/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API, graphqlOperation } from 'aws-amplify';
import { List, Input } from 'antd';
import SmileFilled from '@ant-design/icons/SmileFilled';
import FrownFilled from '@ant-design/icons/FrownFilled';
import Icon from '@ant-design/icons';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { v4 as uuidv4 } from 'uuid';

import checkServerAvailability from 'src/utils/check-server-latency';
import OverloadModal from 'src/components/OverloadModal';
import type { Feedback } from '../../components/FeedbackModal/FeedbackModal.component';
import Header from '../../components/Header';
import FeedbackModal from '../../components/FeedbackModal';
import RefreshModal from '../../components/RefreshModal';
import { addFeedback, pushLatencyMetrics } from '../../graphql/mutations';

import './chat-widget.css';

type SendBodyWS = {
  text: string;
  payload: { previousLanguage: string };
};

type ResponseBodyWS = {
  payload: { text: string; lg?: string };
  text: string;
};

export type ConversationData = {
  id: string;
  text: string;
  sender: 'bot' | 'user';
  feedback?: Feedback;
};

const SendFilled = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.2111 9.10557C20.9482 9.4741 20.9482 10.5259 20.2111 10.8944L2.22328 19.8884C1.41759 20.2912 0.531794 19.507 0.834037 18.6584L3.1986 12.0198C3.31383 11.6962 3.58701 11.4545 3.92214 11.3795L10.0833 9.99997L3.92215 8.62055C3.58702 8.54551 3.31383 8.30376 3.1986 7.98024L0.834038 1.3416C0.531794 0.493034 1.41759 -0.291205 2.22328 0.111638L20.2111 9.10557Z"
      fill="#633EE1"
    />
  </svg>
);

const SERVER_WS = process.env.REACT_APP_SERVER_WS || 'wss://api.uac.calldesk.ai/websocket';

/**
 * Chat Widget component
 *
 * @returns {React.ReactNode} - Chat component
 */
function ChatWidget() {
  const [conversationId] = useState<string>(uuidv4());
  const [userEmail, setUserEmail] = useState<string>();
  const [conversationData, setConversationData] = useState<ConversationData[]>([]);
  const [userInput, setUserInput] = useState('');
  const [conversationLanguage, setConversationLanguage] = useState('en');
  const [shouldConnect, setShouldConnect] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState<ConversationData>(null);
  const [latencyStatus, setLatencyStatus] = useState<string>();
  const [startMessageDate, setStartMessageDate] = useState<number>(null);
  const { sendJsonMessage, readyState } = useWebSocket(
    SERVER_WS,
    {
      onOpen: async () => {
        setStartMessageDate(Date.now());
        sendJsonMessage({ text: 'begin' });
        await API.graphql(
          graphqlOperation(addFeedback, {
            input: {
              feedback: { type: 'email', email: userEmail, tempId: 'give_email' },
              chatHistory: { id: conversationId, conversations: conversationData },
            },
          }),
        );
      },
      onMessage: async event => {
        console.log('[message] Data received from server:', event);

        const message: ResponseBodyWS = JSON.parse(event.data);

        // replace previous temporary text to activate loading bot text
        conversationData[0] = {
          id: uuidv4(),
          text: message?.payload?.text || message?.text,
          sender: 'bot',
        };

        setConversationData(conversationData);
        setConversationLanguage(message.payload?.lg ?? 'en');
        // We don't want to evaluate first message latency because it is generally to fast ~0.5s
        if (conversationData.length >= 2)
          await API.graphql(
            graphqlOperation(pushLatencyMetrics, {
              latency: (Date.now() - startMessageDate) / 1000,
            }),
          );
        setStartMessageDate(null);
      },
      onClose: event => {
        setShouldConnect(false);
        if (event.wasClean) {
          console.log(
            `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`,
          );
        } else {
          console.log('[close] Connection died');
        }
      },
    },
    shouldConnect && latencyStatus === 'OK',
  );
  const { t } = useTranslation();

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const handleUserSendMessage = async () => {
    setConversationData([
      // push a temporary text to null to activate loading text
      { id: uuidv4(), text: null, sender: 'bot' },
      { id: uuidv4(), text: userInput, sender: 'user' },
      ...conversationData,
    ]);
    setUserInput('');

    const sendBody: SendBodyWS = {
      text: userInput,
      payload: { previousLanguage: conversationLanguage },
    };
    setStartMessageDate(Date.now());
    sendJsonMessage(sendBody);
  };

  const resetConversations = () => {
    setConversationData([]);
    setShouldConnect(true);
    sendJsonMessage({ text: '[DONE]' });
    sendJsonMessage({ text: 'begin' });
  };

  const processFeedbackModal = async (itemId: string, feedback: Feedback, closeModal = false) => {
    const indexConversationToUpdate = conversationData.findIndex(
      conversation => conversation.id === itemId,
    );
    conversationData[indexConversationToUpdate].feedback = { ...feedback, email: userEmail };

    // If doesn't exist, that mean feedback modal must be opened
    setOpenFeedbackModal(closeModal ? null : conversationData[indexConversationToUpdate]);

    if (closeModal)
      try {
        // @ts-expect-error data is not undefined, fix to create new type
        const { data } = await API.graphql(
          graphqlOperation(addFeedback, {
            input: {
              feedback,
              chatHistory: { id: conversationId, conversations: conversationData },
            },
          }),
        );

        conversationData[indexConversationToUpdate].feedback = {
          ...feedback,
          id: data.addFeedback.body,
        };

        delete conversationData[indexConversationToUpdate].feedback.tempId;

        setConversationData(conversationData);
      } catch (error) {
        console.log('error', error.errors[0]);
      }
  };

  useEffect(() => {
    const checkServer = async () => {
      const latency = await checkServerAvailability();
      setLatencyStatus(latency);
    };
    checkServer().catch(console.error);
  }, []);

  return (
    <>
      <Header
        handleReload={resetConversations}
        setShouldConnect={setShouldConnect}
        emailInput={{ setUserEmail, userEmail }}
      />
      <div className="ChatWidget">
        <List
          loading={conversationData.length === 0 || connectionStatus === 'Connecting'}
          itemLayout="horizontal"
          dataSource={conversationData}
          renderItem={(item: ConversationData) => (
            <List.Item
              actions={
                item.sender === 'bot' && conversationData.at(-1).id !== item.id && !!item.text
                  ? [
                      <FrownFilled
                        key={'bad-feedback'}
                        style={{
                          color: item?.feedback?.type === 'bad' ? '#d0c5f6' : '#2b137c',
                          fontSize: '24px',
                        }}
                        onClick={() =>
                          processFeedbackModal(item.id, {
                            ...(item.feedback ?? { tempId: uuidv4() }), // temp id
                            type: 'bad',
                          })
                        }
                      />,
                      <SmileFilled
                        key={'good-feedback'}
                        style={{
                          color: item?.feedback?.type === 'good' ? '#d0c5f6' : '#2b137c',
                          fontSize: '24px',
                        }}
                        onClick={() =>
                          processFeedbackModal(item.id, {
                            ...(item.feedback ?? { tempId: uuidv4() }), // temp id
                            type: 'good',
                          })
                        }
                      />,
                    ]
                  : []
              }
            >
              <List.Item.Meta
                description={item.text || <div className="dot-flashing"></div>}
                className={`${item.sender}-list-item-meta`}
              />
            </List.Item>
          )}
          footer={
            <>
              <Input.TextArea
                autoSize
                value={userInput}
                disabled={conversationData.length === 0 || conversationData[0]?.text === null}
                placeholder={t`ChatWidget.inputPlaceholder`}
                onChange={event => setUserInput(event.target.value)}
                onPressEnter={userInput && handleUserSendMessage}
              />
              <Icon
                component={SendFilled}
                className={userInput ? 'send-icon-enabled ' : 'send-icon-disabled '}
                onClick={userInput && handleUserSendMessage}
              />
            </>
          }
        />
      </div>
      {openFeedbackModal && (
        <FeedbackModal
          conversationData={openFeedbackModal}
          processFeedbackModal={processFeedbackModal}
        />
      )}
      {connectionStatus === 'Closed' && <RefreshModal refreshConversation={resetConversations} />}
      {latencyStatus === 'OVERLOAD' && <OverloadModal />}
    </>
  );
}

export default ChatWidget;
