import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './locales/en.json';
import arTranslation from './locales/ar.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: { en: { translation: enTranslation }, ar: { translation: arTranslation } },
    fallbackLng: 'en',
    // Options for LanguageDetector plugin use to detect user language. Set localStorage key "i18nextLng" with language to change language manually
    detection: {
      order: ['localStorage', 'sessionStorage', 'cookie', 'navigator', 'htmlTag'],
    },
    debug: true,
  });

export default i18n;
