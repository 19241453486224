const SERVER_WS = process.env.REACT_APP_SERVER_WS || 'wss://api.uac.calldesk.ai/websocket';

async function checkServerLatency(): Promise<'OK' | 'OVERLOAD'> {
  return new Promise(resolve => {
    const socket = new WebSocket(SERVER_WS);
    const maxLatency: number = parseInt(process.env.REACT_APP_MAX_LATENCY || '15000', 10); // milliseconds
    let startTime: number;
    let latency: number; // milliseconds

    socket.onopen = () => {
      socket.send(JSON.stringify({ text: 'begin' }));
    };

    socket.onmessage = event => {
      const beginChat = 'Welcome to the Universal chatbot demo by FLITC';
      const message = JSON.parse(event.data);
      if (message.text?.startsWith(beginChat) || message.payload?.text.startsWith(beginChat)) {
        startTime = Date.now();
        socket.send(
          JSON.stringify({
            text: 'If you could dinner with one person, dead or alive, who could that be ?',
            payload: { previousLanguage: 'en' },
          }),
        );
      } else {
        latency = Date.now() - startTime;
        socket.close();
        resolve(latency > maxLatency ? 'OVERLOAD' : 'OK');
      }
    };

    setTimeout(() => resolve('OVERLOAD'), maxLatency);
  });
}

export default checkServerLatency;
