import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UndoOutlined from '@ant-design/icons/UndoOutlined';
import InfoCirlcleOutlined from '@ant-design/icons/InfoCircleOutlined';

import StepsModal from '../StepsModal';

import './header.css';

import labeahLogo from '../../uac_logo.svg';

type Props = {
  handleReload: () => void;
  setShouldConnect: (shouldConnect: boolean) => void;
  emailInput: {
    userEmail: string;
    setUserEmail: (userEmail: string) => void;
  };
};

function Header({ handleReload, emailInput, setShouldConnect }: Props) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(true);

  const closeModal = (force = false) => {
    if (!emailInput.userEmail && !force) return;
    setModalOpen(false);
    setShouldConnect(true);
  };

  return (
    <div className="Header">
      <UndoOutlined onClick={handleReload} rotate={90} />
      <div className="logoSection">
        <img src={labeahLogo} alt="Labeah logo" />
        Prototype version for LEAP
      </div>
      <InfoCirlcleOutlined onClick={() => setModalOpen(true)} />
      {modalOpen && (
        <StepsModal
          title={
            <>
              <InfoCirlcleOutlined style={{ fontSize: '24px', marginRight: '16px' }} />
              <p>{t`StepsModal.title`}</p>
            </>
          }
          emailInput={emailInput}
          closeModal={closeModal}
        />
      )}
    </div>
  );
}

export default Header;
