import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Modal } from 'antd';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import SmileFilled from '@ant-design/icons/SmileFilled';

import './steps-modal.css';

type Props = {
  title: string | ReactNode;
  emailInput: {
    userEmail: string;
    setUserEmail: (userEmail: string) => void;
  };
  closeModal: (force?: boolean) => void;
};

// ToDo: turn into generic component
function StepsModal({ title, emailInput, closeModal }: Props) {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();

  const stepsContent = [
    {
      content: (
        <>
          <div>
            <QuestionCircleOutlined />
            <p>{t`StepsModal.disclaimer-1`}</p>
          </div>
          <div>
            <UserOutlined />
            <p>{t`StepsModal.disclaimer-2`}</p>
          </div>
        </>
      ),
    },
    {
      content: (
        <>
          <div>
            <WarningOutlined />
            <p>{t`StepsModal.disclaimer-3`}</p>
          </div>
          <div>
            <SmileFilled />
            <p>{t`StepsModal.disclaimer-4`}</p>
          </div>
        </>
      ),
    },
    {
      content: (
        <>
          <p className="email-label">{t`StepsModal.labelEmail`}</p>
          <Form.Item
            className="email-step"
            name="userEmail"
            rules={[
              { required: true, message: t`StepsModal.errorEmptyEmail` },
              { type: 'email', message: t`StepsModal.errorNotValidEmail` },
            ]}
            required
          >
            <Input placeholder="email@example.com" />
          </Form.Item>
        </>
      ),
    },
  ];

  const onFinish = (values: { userEmail: string }) => {
    emailInput.setUserEmail(values.userEmail);
    closeModal(true);
  };

  useEffect(
    () => () => {
      setCurrent(0);
    },
    [],
  );

  return (
    <Modal
      open
      className="StepsModal"
      onCancel={() => closeModal()}
      maskClosable={false}
      closable={!!emailInput.userEmail}
      title={title}
      zIndex={2000}
      footer={[
        current > 0 && (
          <Button
            className={'controlButton'}
            style={{ margin: '0 8px' }}
            onClick={() => setCurrent(current - 1)}
          >
            {t`StepsModal.backButton`}
          </Button>
        ),
        current === stepsContent.length - 1 && (
          <Button className={'okButton'} form={'emailForm'} htmlType="submit">
            {t`StepsModal.okButton`}
          </Button>
        ),
        current < stepsContent.length - 1 && (
          <Button className={'controlButton'} onClick={() => setCurrent(current + 1)}>
            {t`StepsModal.nextButton`}
          </Button>
        ),
      ]}
    >
      <Form
        form={form}
        id="emailForm"
        initialValues={{ userEmail: emailInput.userEmail }}
        onFinish={onFinish}
      >
        {stepsContent[current].content}
      </Form>
    </Modal>
  );
}

export default StepsModal;
