/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next';
import { Modal, Input, Button, Checkbox, Form } from 'antd';
import SmileFilled from '@ant-design/icons/SmileFilled';
import FrownFilled from '@ant-design/icons/FrownFilled';

import type { ConversationData } from '../../pages/ChatWidget/ChatWidget.component';

import './feedback-modal.css';

export type FeedbackType = 'good' | 'bad' | 'email';
export type Feedback = {
  id?: string;
  type: FeedbackType;
  botShouldAnswer?: string;
  observations?: string[];
  tempId: string;
  email?: string;
};

type Props = {
  conversationData: ConversationData;
  processFeedbackModal: (
    itemId: string,
    feedback: Feedback,
    closeModal?: boolean,
  ) => Promise<void> | void;
};

function FeedbackModal({ conversationData, processFeedbackModal }: Props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { feedback } = conversationData ?? {};

  const textareaFeedback = (
    <Form.Item name="botShouldAnswer">
      <Input.TextArea placeholder={t`FeedbackModal.botShouldAnswer`} />
    </Form.Item>
  );
  const modalComponent = {
    good: {
      content: textareaFeedback,
      titleIcon: <SmileFilled style={{ color: '#d0c5f6', fontSize: '24px' }} />,
    },
    bad: {
      content: (
        <>
          {textareaFeedback}
          <Form.Item name="observations">
            <Checkbox.Group
              options={[
                t`FeedbackModal.observation-1`,
                t`FeedbackModal.observation-2`,
                t`FeedbackModal.observation-3`,
              ]}
              value={[
                'The answer was not true',
                'The answer was not safe',
                'The answer was of no interest',
              ]}
            />
          </Form.Item>
        </>
      ),
      titleIcon: <FrownFilled style={{ color: '#d0c5f6', fontSize: '24px' }} />,
    },
  }[feedback?.type];

  const onFinish = async (values: Partial<Feedback>) => {
    console.log('Success:', values);

    const newFeedback: Feedback = { ...feedback, ...values };

    await processFeedbackModal(conversationData.id, newFeedback, true);
    form.resetFields();
  };

  return (
    <Modal
      className="FeedbackModal"
      open={!!feedback?.type}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onCancel={() => processFeedbackModal(conversationData.id, feedback, true)}
      closable
      title={
        <>
          {modalComponent?.titleIcon} <p>{t`FeedbackModal.title`}</p>
        </>
      }
      footer={[
        <Button form="feedbackForm" key="submit-feedback" htmlType="submit">
          {t`FeedbackModal.submit`}
        </Button>,
      ]}
    >
      <Form
        form={form}
        initialValues={conversationData?.feedback}
        id="feedbackForm"
        onFinish={onFinish}
      >
        {modalComponent?.content}
      </Form>
    </Modal>
  );
}

export default FeedbackModal;
